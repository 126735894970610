import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"
import useDataObjectTransform from "./useDataObjectTransform"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

const useGeoData = () => {
  const { geoEntitiesDataTransform, countryByDestinationTransform } = useDataObjectTransform()
  /**
   * Get data for a given country.
   * @param {number} country_id The viewed country id.
   * @return {Promise<GeoEntityProps[]>}
   */
  const getGeoData = async (country_id) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "geo_entity_index",
        ss_type: '("city" OR "landmark" OR "state" OR region)',
        its_country_nid: country_id,
      })
      .sort({
        id: "asc",
      })
      .rows(500)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return geoEntitiesDataTransform(solrRecords)
  }

  /**
   * Get destinations.
   * @return {Promise<GeoEntityProps[]>}
   */

  const getGeoDestinationData = async () => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "geo_entity_index",
        ss_type: "destination",
      })
      .sort({
        id: "asc",
      })

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return geoEntitiesDataTransform(solrRecords)
  }

  /**
   * Get countries based on destination id.
   * @return {Promise<GeoEntityProps[]>}
   * @param {string} id
   */

  const getGeoCountriesByDestinationData = async (id) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "geo_entity_index",
        ss_type: "country",
        its_destinations_nid: id,
      })
      .sort({
        id: "asc",
      })
      .rows(100)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return {
      id,
      items: countryByDestinationTransform(solrRecords),
    }
  }

  return {
    getGeoData,
    getGeoDestinationData,
    getGeoCountriesByDestinationData,
  }
}

export default useGeoData
