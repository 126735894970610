import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

const useTripsData = () => {
  const { tripDataFilterAndTransform, tripOverrideTransform } = useDataObjectTransform()

  const getOverrideTrips = (trips, expirationDate) => {
    const isExpired = expirationDate ? Date.parse(expirationDate) < Date.now() : false

    let override = []

    if (trips && trips.length > 0 && !isExpired) {
      override = tripOverrideTransform(trips)
    }

    return override
  }

  const getTripsData = async (nodeId, component = null) => {
    const overrideTrips = component
      ? getOverrideTrips(component?.trip_list_override, component?.expire_date)
      : []

    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      // Query that have group field unset (null) or false (not true).
      .customq(["-bs_groups:true OR (*:* -bs_groups:[* TO *])"])
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        ...(nodeId && { itm_primary_country_destination_nid: nodeId }),
      })
      .sort({ bs_special_offer: "desc", its_popularity: "desc" })
      .rows(nodeId ? 9 : 10)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const transform = tripDataFilterAndTransform(solrRecords)
    return [...overrideTrips, ...transform].slice(0, 9)
  }

  const getTripsByCountryAndDestination = async (
    destinationId,
    countryId = null,
    suggestedTrips = null,
    activeTripId = null
  ) => {
    let results = []
    const tripIds = new Set()
    const overrideTrips = suggestedTrips
      ? getOverrideTrips(suggestedTrips.trip_list_override, suggestedTrips.expire_date)
      : []

    if (countryId) {
      // First, get country-based trips
      const solrQuery = new SolrQueryBuilder(queryConfig)
      solrQuery
        .customq(["-bs_groups:true OR (*:* -bs_groups:[* TO *])"])
        .q({
          index_id: "trip_index",
          ss_type: "trip",
          its_primary_country_country_nid: countryId,
        })
        .sort({ bs_special_offer: "desc", its_popularity: "desc" })
        .rows(10)

      const countryRes = await solrClient.runQuery(solrQuery)
      const countrySolrRecords = countryRes?.response?.docs || []

      countrySolrRecords.forEach((record) => {
        results.push(record)
        tripIds.add(record.its_nid)
      })
    }

    if (results.length < 3 || (results.length <= 3 && activeTripId)) {
      // If we don't have at least 3 results based on country, get destination-based trips
      const solrQuery = new SolrQueryBuilder(queryConfig)
      solrQuery
        .customq(["-bs_groups:true OR (*:* -bs_groups:[* TO *])"])
        .q({
          index_id: "trip_index",
          ss_type: "trip",
          ...(destinationId && { itm_primary_country_destination_nid: destinationId }),
        })
        .sort({ bs_special_offer: "desc", its_popularity: "desc" })
        .rows(10)

      const destinationRes = await solrClient.runQuery(solrQuery)
      const destinationSolrRecords = destinationRes?.response?.docs || []

      destinationSolrRecords.forEach((record) => {
        if (!tripIds.has(record.its_nid)) {
          results.push(record)
        }
      })
    }

    if (activeTripId) {
      // filter results to exclude active trip
      results = results.filter((trip) => trip.its_nid[0] !== activeTripId)
    }
    const slicedResults = results.slice(0, 9)
    const transformedTrips = tripDataFilterAndTransform(slicedResults)

    return [...overrideTrips, ...transformedTrips]
  }

  const getGroupTripsByDestination = async (destinationId, startIndex, pageSize = 10) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        bs_groups: true,
        ...(destinationId && { itm_primary_country_destination_nid: destinationId }),
      })
      .sort({ bs_special_offer: "desc", its_popularity: "desc" })
      .start(startIndex)
      .rows(pageSize)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null

    return {
      currentPage: Math.ceil(startIndex / pageSize) + 1,
      pages: Math.ceil(response?.response?.numFound / pageSize),
      items: tripDataFilterAndTransform(solrRecords),
    }
  }

  const getTripsByTravelStyle = async (travelStyleId, startIndex, pageSize = 10) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        bs_groups: true,
        ...(travelStyleId && { itm_travel_style_nid: travelStyleId }),
      })
      .sort({ bs_special_offer: "desc", its_popularity: "desc" })
      .start(startIndex)
      .rows(pageSize)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null

    return {
      currentPage: Math.ceil(startIndex / pageSize) + 1,
      pages: Math.ceil(response?.response?.numFound / pageSize),
      items: tripDataFilterAndTransform(solrRecords),
    }
  }

  const getTripsByNid = async (ids, amount) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        its_nid: `(${ids})`,
      })
      .sort({ id: "asc" })
      .rows(amount)
    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return tripDataFilterAndTransform(solrRecords, true)
  }

  const getTripsBySpecial = async (destinationId, startIndex, pageSize = 10) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        bs_special_offer: true,
        ...(destinationId && { itm_primary_country_destination_nid: destinationId }),
      })
      .sort({ bs_special_offer: "desc", its_popularity: "desc" })
      .start(startIndex)
      .rows(pageSize)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null

    return {
      currentPage: Math.ceil(startIndex / pageSize) + 1,
      pages: Math.ceil(response?.response?.numFound / pageSize),
      items: tripDataFilterAndTransform(solrRecords),
    }
  }

  return {
    getTripsData,
    getTripsByNid,
    getTripsByTravelStyle,
    getGroupTripsByDestination,
    getTripsBySpecial,
    getTripsByCountryAndDestination,
  }
}

export default useTripsData
