// eslint-disable-next-line no-unused-vars
import React, { Context, createContext } from "react"

const PHONE = "1.800.557.2841"
const PHONE_URL = "tel:18005572841"

/**
 * @typedef {Object} SiteMetadata
 * @property {string} phone
 * @property {string} phoneUrl
 */

/**
 * @type {Context<SiteMetadata>}
 */
export const SiteMetadataContext = createContext({
  phone: PHONE, // This value should be changed in siteMetaDataProvider.jsx
  phoneUrl: PHONE_URL, // This value should be changed in siteMetaDataProvider.jsx
})

/**
 * @typedef {Object} ProvidersProps
 * @property {JSX.Element} children
 */

/**
 * Composition of providers dependencies.
 * @param {ProvidersProps} props
 * @return {JSX.Element}
 */
const SiteMetadataProvider = ({ children }) => {
  // This causes issues with chromatic, so we're disabling it for now.
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         phone
  //         phoneUrl
  //       }
  //     }
  //   }
  // `)

  return (
    <SiteMetadataContext.Provider
      value={{
        phone: PHONE,
        phoneUrl: PHONE_URL,
      }}
    >
      {children}
    </SiteMetadataContext.Provider>
  )
}
export default SiteMetadataProvider
