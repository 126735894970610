import { useState, useCallback, useEffect, useMemo, useRef } from "react"
import { type UseFormReturn, type FieldValues, useForm } from "react-hook-form"
import { useUrlParams } from "@ggs/hooks"
import {
  getLabelFromString,
  getIdFromString,
  formatSearchDataByCountry,
  setFormFields,
  getRangeFromString,
} from "../../components/TripGeneralSearch/helpers"
import { useTripsSearchData } from "./"
import { omit } from "lodash"
import { useSelector } from "@ggs/store"

const PAGE_SIZE = 9

type FilterRangeValue = {
  min: number | string | null
  max: number | string | null
}

interface TripSearchParamsState {
  country?: string
  location?: string
  tripStyles?: string
  tripTypes?: string
  duration?: FilterRangeValue
  budget?: FilterRangeValue
  sort?: string
  pageIndex?: number
  pageSize?: number
}

interface TripSearchFacetItem {
  label: string
  type: string
  experienceType?: string
}

interface TripSearchStateParams {
  path?: string
  defaultState?: any
  ignoreFilters?: string[]
}

interface TripSearchStateHook {
  handlers: {
    onApplyFilter: (filter: any, type: string) => void
    onPageChange: (page: number, callback: (() => void) | null) => void
    onSortChange: (type: string) => void
    removeAllFilters: () => void
    removeIndividualFilter: (facet: any) => void
    onSpecialsToggle: (value: boolean) => void
  }
  state: {
    urlParams: Record<string, any>
    searchState: TripSearchParamsState
    usedFilters: TripSearchFacetItem[]
  }
  form: UseFormReturn<any, any, undefined>
}

const useTripsSearchState = ({
  path = "",
  defaultState = {},
  ignoreFilters = [],
}: TripSearchStateParams): TripSearchStateHook => {
  const methods = useForm({
    defaultValues: {
      country: null,
      location: null,
      experiences: [],
      budget: [],
      duration: [],
    },
  })

  const userCountry = useSelector((state) => state.settings.country)
  const currentCountry = useRef(userCountry?.code || "")
  const { getUserPriceSortField } = useTripsSearchData()
  const { reset, setValue, getValues } = methods
  const { params, setParam, resetParams } = useUrlParams(
    {
      country: null,
      location: null,
      tripStyles: null,
      tripTypes: null,
      duration: null,
      budget: null,
      page: null,
      sort: null,
      specials: null,
    },
    path
  )

  const composeParamsState = () => ({
    ...(params.country && { country: getIdFromString(params.country as string) }),
    ...(params.location && { location: getIdFromString(params.location as string) }),
    ...(params.tripStyles && { tripStyles: getIdFromString(params.tripStyles as string) }),
    ...(params.tripTypes && { tripTypes: getIdFromString(params.tripTypes as string) }),
    ...(params.duration && { duration: getRangeFromString(params.duration as string) }),
    ...(params.budget && { budget: getRangeFromString(params.budget as string) }),
    ...(params.page && {
      pageIndex: Math.max((parseInt(params.page as string) - 1) * PAGE_SIZE, 0),
    }),
    pageSize: PAGE_SIZE,
    specials: false,
    ...defaultState,
  })

  const [searchParams, setSearchParams] = useState<TripSearchParamsState>(composeParamsState())

  const setUrlParam = (value: any, type: string) => {
    if (value) {
      if (type === "experiences") {
        const tripTypes: string[] = []
        const tripStyles: string[] = []
        value.forEach((filter: any) => {
          const labelId = [filter.label, filter.value].join(":")
          filter?.experience === "Trip Types" ? tripTypes.push(labelId) : tripStyles.push(labelId)
        })
        if (tripTypes.length > 0) setParam("tripTypes", tripTypes.join(","))
        else setParam("tripTypes", null)
        if (tripStyles.length > 0) setParam("tripStyles", tripStyles.join(","))
        else setParam("tripStyles", null)
      } else if (["budget", "duration"].includes(type)) {
        const range = value.join("-")
        setParam(type, range)
      } else {
        const labelId = [value.label, value.value].join(":")
        setParam(type, labelId)
      }
    } else {
      setParam(type, null)
    }
    setParam("page", 1)
  }

  const onApplyFilter = useCallback((filter: any, type: string) => {
    if (ignoreFilters.includes(type)) return
    // set new url params
    setUrlParam(filter, type)
    // apply new filtering
    if (type === "experiences") {
      const tripStyles: string[] = []
      const tripTypes: string[] = []
      filter.forEach((item: any) => {
        if (item?.experience === "Trip Styles") tripStyles.push(item?.value)
        else tripTypes.push(item?.value)
      })
      setSearchParams((params: any) => ({
        ...params,
        ...defaultState,
        tripStyles: tripStyles.join(","),
        tripTypes: tripTypes.join(","),
      }))
    } else if (["budget", "duration"].includes(type)) {
      setSearchParams((params: any) => ({
        ...params,
        pageIndex: 0,
        [type]: {
          min: filter[0] || null,
          max: filter[1] || null,
        },
      }))
    } else {
      setSearchParams((params: any) => ({
        ...params,
        ...defaultState,
        pageIndex: 0,
        [type]: filter?.value || null,
      }))
    }
  }, [])

  const onPageChange = useCallback((page: number, callback: (() => void) | null = null) => {
    const pageIndex = Math.max((page - 1) * PAGE_SIZE, 0)
    setParam("page", `${page}`)
    setSearchParams((params: any) => ({
      ...params,
      pageIndex,
    }))
    if (callback) callback()
  }, [])

  const onSortChange = useCallback(
    (type: string) => {
      const [fieldKey, fieldDirection] = type.split("-")
      let sortField = fieldKey

      if (fieldKey === "price") {
        sortField = getUserPriceSortField()
      }

      setParam("sort", `${sortField}-${fieldDirection}`)
      setSearchParams((params: any) => ({
        ...params,
        sortField: sortField,
        sortDirection: fieldDirection,
      }))
    },
    [userCountry]
  )

  const onSpecialsToggle = useCallback((value: boolean) => {
    setParam("specials", value)
    setSearchParams((params: any) => ({
      ...params,
      pageIndex: 0,
      specials: Boolean(value),
    }))
  }, [])

  const removeAllFilters = useCallback(() => {
    const defaultValues = {
      country: null,
      location: null,
      tripStyles: null,
      tripTypes: null,
      budget: { min: null, max: null },
      duration: { min: null, max: null },
      pageIndex: 0,
      specials: false,
    }

    const extraFields = ignoreFilters.includes("experiences") ? ["tripStyles", "tripTypes"] : []
    const omitFields = [...ignoreFilters, ...extraFields]
    const finalDefaultValues = omit(defaultValues, omitFields)
    setSearchParams((params: any) => ({ ...params, ...finalDefaultValues }))
    // reset URL query params
    resetParams({ page: params.page })
    // reset form fields
    reset()
    // change to first page
    setParam("page", 1)
  }, [])

  const removeIndividualFilter = (facet: any) => {
    if (ignoreFilters.includes(facet.type)) return
    if (facet.type === "experience") {
      const { experiences } = getValues()
      setValue(
        "experiences",
        experiences.filter((item: any) => item.label !== facet.label)
      )
      if (facet?.experienceType === "tripStyles") {
        const newParam = params?.tripStyles
          //@ts-ignore
          ?.split(",")
          ?.filter((value: string) => facet.label !== value.split(":")[0])

        setParam("tripStyles", newParam.join(","))
      }
      if (facet?.experienceType === "tripTypes") {
        const newParam = params?.tripTypes
          //@ts-ignore
          ?.split(",")
          ?.filter((value: string) => facet.label !== value.split(":")[0])

        setParam("tripTypes", newParam.join(","))
      }
    } else {
      setValue(facet?.type, null)
      setParam(facet?.type, null)
    }

    const newSearchParams = formatSearchDataByCountry(getValues() as any)
    const newSearchState = {
      ...params,
      ...newSearchParams,
      pageIndex: 0,
      ...defaultState,
    }

    setSearchParams((params: TripSearchParamsState) => ({ ...searchParams, ...newSearchState }))
  }

  useEffect(() => {
    const fields: any = setFormFields(params)
    Object.keys(fields).forEach((field: any) => {
      if (["budget", "duration"].includes(field)) {
        setValue(field, fields[field]?.value)
      } else setValue(field, fields[field])
    })
    // set sort field if it exists in query params when page refreshes
    if (params.sort) onSortChange(params.sort as string)
    // set specials checkbox if it exists in query params when page refreshes
    if (params.specials) onSpecialsToggle(params.specials === "true")
  }, [])

  // Reset if country change, ensure price and sort fields don't stick to previous country currency.
  useEffect(() => {
    if (currentCountry.current !== "" && currentCountry.current !== userCountry?.code) {
      removeAllFilters()
      currentCountry.current = userCountry?.code
    }
  }, [userCountry])

  const usedFilters = useMemo(() => {
    const facets = []
    if (params?.location) {
      facets.push({ label: getLabelFromString(params?.location as string), type: "location" })
    }

    if (params?.country) {
      facets.push({ label: getLabelFromString(params?.country as string), type: "country" })
    }

    if (params?.tripStyles) {
      const styles = getLabelFromString(params?.tripStyles as string).split(",")
      styles?.forEach((style: string) =>
        facets.push({ label: style, type: "experience", experienceType: "tripStyles" })
      )
    }

    if (params?.tripTypes) {
      const types = getLabelFromString(params?.tripTypes as string).split(",")
      types?.forEach((type: string) =>
        facets.push({ label: type, type: "experience", experienceType: "tripTypes" })
      )
    }

    if (params?.budget) {
      const budget = getLabelFromString(params?.budget as string).split("-")
      facets.push({ label: budget.join(" - "), type: "budget" })
    }

    if (params?.duration) {
      const duration = getLabelFromString(params?.duration as string).split("-")
      facets.push({ label: duration.join(" - "), type: "duration" })
    }
    return facets
  }, [params])

  return {
    handlers: {
      onApplyFilter,
      onPageChange,
      onSortChange,
      removeAllFilters,
      removeIndividualFilter,
      onSpecialsToggle,
    },
    state: {
      searchState: searchParams,
      urlParams: params,
      usedFilters,
    },
    form: methods,
  }
}

export default useTripsSearchState
