import { initialState } from "../"
import { createReducer } from "@reduxjs/toolkit"
import { sessionActionType } from "../actions/session"

/**
 * @typedef {import("../initialState").StateSession} InitialStateSession
 * @typedef {import("../actions/session").SetDrupalTokenAction} SetDrupalTokenAction
 */

/**
 * @returns {StateSettingsType} New Settings state.
 */
const session = createReducer(initialState.session, {
  /**
   * Set Drupal token to session state.
   * @param {InitialStateSession} state
   * @param {SetDrupalTokenAction} action
   */
  [sessionActionType.SET_DRUPAL_TOKEN]: (state, action) => {
    state.drupalToken = action.payload.token || ""
  },

})

export default session
