import { useEffect, useState } from "react"

type ScrollDirection = "UP" | "DOWN" | null

export default function useScrollDirection(offset = 40) {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(null)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? "DOWN" : "UP"
      const threshold = 10

      if (
        direction !== scrollDirection &&
        scrollY > offset &&
        (scrollY - lastScrollY > threshold || scrollY - lastScrollY < -threshold)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }
    window.addEventListener("scroll", updateScrollDirection) // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection) // clean up
    }
  }, [scrollDirection])

  return scrollDirection
}
