import { initialState } from "../initialState"
import { viewsActionType } from "../actions/views"
import { createReducer, current } from "@reduxjs/toolkit"
import { get, isArray, reject } from "lodash"

/**
 * @typedef {import("../initialState").StateViews} StateViews
 * @typedef {import("../initialState").DrupalNodes} DrupalNodes
 * @typedef {import("../actions/views").AddNodeAction} AddNodeAction
 * @typedef {import("../actions/views").RemoveNodeAction} RemoveNodeAction
 * @typedef {import("../actions/views").SetIndexAction} SetIndexAction
 */

/**
 * @returns {StateViews} New views state.
 */
const views = createReducer(initialState.view, {
  /**
   * Add node to views nodes store.
   * @param {StateViews} state
   * @param {AddNodeAction} action
   */
  [viewsActionType.ADD_NODE]: (state, action) => {
    const type = /** @type {keyof DrupalNodes} */ (action.payload.type)
    const entity = action.payload.entity
    const idPropertyKey = action.payload.idPropertyKey
    const entityId = get(entity, idPropertyKey, null)
    const nodes = current(state.wishlist)
    const nodesSet = nodes[type]

    // Unexpected missing entity ID, cannot proceed with the state change.
    if (!entityId) {
      return
    }

    const filteredNodes = reject(nodesSet, {
      [idPropertyKey]: entityId,
    })

    if (isArray(filteredNodes)) {
      // @ts-ignore
      state.wishlist[type] = filteredNodes.concat(entity)
    } else {
      // @ts-ignore
      state.wishlist[type] = [entity]
    }
  },

  /**
   * Remove node to views nodes store.
   * @param {StateViews} state
   * @param {RemoveNodeAction} action
   */
  [viewsActionType.REMOVE_NODE]: (state, action) => {
    const type = /** @type {keyof DrupalNodes} */ (action.payload.type)
    const findPredicate = action.payload.findPredicate || {}
    const nodes = current(state.wishlist)
    const nodesSet = nodes[type]

    const filteredNodes = reject(nodesSet, findPredicate)
    if (isArray(filteredNodes)) {
      // @ts-ignore
      state.wishlist[type] = filteredNodes
    } else {
      // @ts-ignore
      state.wishlist[type] = []
    }
  },

  /**
   * Add index into views index.
   * @param {StateViews} state
   * @param {SetIndexAction} action
   */
  [viewsActionType.SET_INDEX]: (state, action) => {
    const indexId = action.payload.id
    state.index[indexId] = action.payload.index || {}
  },
})

export default views
