import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer, createMigrate } from "redux-persist"
import createWebStorage from "redux-persist/lib/storage/createWebStorage"
import thunk from "redux-thunk"
import { useSelector as useSelectorBase } from "react-redux"
import rootReducer from "./reducers/rootReducer"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { composeWithDevTools } from "redux-devtools-extension"
import { APP_STORE_KEY, initialState } from "./initialState"

/**
 * @typedef {import("redux").Action} Action
 * @typedef {import("redux-thunk").ThunkAction<void, Function, unknown, Action>} AppThunk
 * @typedef {import("redux-persist").PersistedState} PersistedState
 * @typedef {import("./initialState").DefaultState} DefaultState
 * @typedef {import('redux-persist').MigrationManifest} MigrationManifest
 */

/**
 * @typedef {DefaultState & PersistedState} PersistedDefaultState
 */

/**
 * @type {MigrationManifest}
 */
const migrations = {
  1: (/** @type {PersistedDefaultState} */ state) => {
    return {
      ...state,
      initialState,
    }
  },
}

// Noop storage using on server side.
const createNoopStorage = () => {
  return {
    /**
     * Get item.
     * @param {string} key
     * @return {Promise<null>}
     */
    getItem(key) {
      return Promise.resolve(null)
    },

    /**
     * Set item.
     * @param {string} key
     * @param {string} value
     * @return {Promise<string>}
     */
    setItem(key, value) {
      return Promise.resolve(value)
    },

    /**
     * Remove item.
     * @param {string} key
     * @return {Promise<void>}
     */
    removeItem(key) {
      return Promise.resolve()
    },
  }
}

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage()
const persistConfig = {
  key: APP_STORE_KEY,
  storage,
  stateReconciler: autoMergeLevel2,
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ["session", "ui"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
export const persistor = persistStore(store)

/**
 * @type {import("./types").TypedUseSelector}
 */
export const useSelector = useSelectorBase
