import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

/**
 * Hook to consume article category solr index data and transform it into a UI component format.
 */
const useArticleCategoryData = () => {
  const { articleCategoryTransform } = useDataObjectTransform()
  const getArticleCategoryData = async () => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_category_index",
        ss_type: "article_category",
      })
      .sort({
        id: "asc",
      })
      .rows(20)
    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return articleCategoryTransform(solrRecords)
  }

  return { getArticleCategoryData }
}

export default useArticleCategoryData
