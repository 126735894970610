/**
 * Views action types.
 * @enum {string}
 */
export const viewsActionType = {
  ADD_NODE: "ADD_NODE",
  ADD_NODES: "ADD_NODES",
  REMOVE_NODE: "REMOVE_NODE",
  SET_INDEX: "SET_INDEX",
  REMOVE_INDEX: "REMOVE_INDEX",
}

/**
 * @typedef {Object} AddNodeActionPayload
 * @property {string} type Entity type
 * @property {string} idPropertyKey Property key of entity ID, to support existing entity lookup and prevent duplicates
 * @property {Record<string, any>} entity Entity object
 */

/**
 * @typedef {Object} AddNodesActionPayload
 * @property {string} type Entity type
 * @property {string} idPropertyKey Property key of entity ID, to support existing entity lookup and prevent duplicates
 * @property {Record<string, any>[]} entities Entity object
 */

/**
 * @typedef {Object} AddNodeAction
 * @property {viewsActionType} type Action type key
 * @property {AddNodeActionPayload=} payload Action payload data
 */

/**
 * @typedef {Object} AddNodesAction
 * @property {viewsActionType} type Action type key
 * @property {AddNodesActionPayload=} payload Action payload data
 */

/**
 * @typedef {Object} AddNodeActionProps
 * @property {string} type Entity type
 * @property {Record<string, any>} entity Entity object
 * @property {string} idPropertyKey Property key of entity ID, to support existing entity lookup and prevent duplicates
 */

/**
 * @typedef {Object} AddNodesActionProps
 * @property {string} type Entity type
 * @property {Record<string, any>[]} entities Entity object
 * @property {string} idPropertyKey Property key of entity ID, to support existing entity lookup and prevent duplicates
 */

/**
 * Action to add node entity to Redux views state.
 * @param {AddNodeActionProps} props
 * @return {AddNodeAction} Action object.
 */
export const addNode = ({ type, entity, idPropertyKey }) => {
  return {
    type: viewsActionType.ADD_NODE,
    payload: {
      type,
      idPropertyKey,
      entity,
    },
  }
}

/**
 * Action to add node entity to Redux views state.
 * @param {AddNodesActionProps} props
 * @return {AddNodesAction} Action object.
 */
export const addNodes = ({ type, entities, idPropertyKey }) => {
  return {
    type: viewsActionType.ADD_NODES,
    payload: {
      type,
      idPropertyKey,
      entities,
    },
  }
}

/**
 * @typedef {Object<string, any>} NodeSelectPredicate
 */

/**
 * @typedef {Object} RemoveNodeActionPayload
 * @property {string} type Entity type
 * @property {NodeSelectPredicate} findPredicate Lodash lookup predicate to lookup the node to remove
 */

/**
 * @typedef {Object} RemoveNodeAction
 * @property {viewsActionType} type Action type key
 * @property {RemoveNodeActionPayload} payload Action payload data
 */

/**
 * @typedef {Object} RemoveNodeActionProps
 * @property {string} type Entity type
 * @property {NodeSelectPredicate} findPredicate Predicate to find entity within nodes array
 */

/**
 * Action to remove node entity to Redux views state.
 * @param {RemoveNodeActionProps} props
 * @return {RemoveNodeAction} Action object.
 */
export const removeNode = ({ type, findPredicate }) => {
  return {
    type: viewsActionType.REMOVE_NODE,
    payload: {
      type,
      findPredicate,
    },
  }
}

/**
 * @typedef {Object} SetIndexActionPayload
 * @property {string} id Index ID
 * @property {Object} index Content index
 */

/**
 * @typedef {Object} SetIndexAction
 * @property {viewsActionType} type Action type key
 * @property {SetIndexActionPayload} payload Action payload data
 */

/**
 * @typedef {Object} SetIndexActionProps
 * @property {string} id Index ID
 * @property {Object} index Content index
 */

/**
 * Action to set content index to Redux views state.
 * @param {SetIndexActionProps} props
 * @return {SetIndexAction} Action object.
 */
export const setIndex = ({ id, index }) => {
  return {
    type: viewsActionType.SET_INDEX,
    payload: {
      id,
      index,
    },
  }
}

/**
 * @typedef {Object} UnsetIndexAction
 * @property {viewsActionType} type Action type key
 * @property {null} payload
 */

/**
 * Action to unset content index to Redux views state.
 * @return {UnsetIndexAction} Action object.
 */
export const unsetIndex = () => {
  return {
    type: viewsActionType.SET_INDEX,
    payload: null,
  }
}
