/**
 * Transform assessment reports for serviceC comparison.
 * @param {string} text text
 * @param {number} length string length
 * @return {string} string
 */
const truncateString = (text, length = 15) => {
  if (text) {
    if (length === 0) return text
    if (text?.length < length) return text
    return text.slice(0, length) + "..."
  }
  return null
}

export default truncateString
