import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"
import { sampleSize } from "lodash"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

/**
 * Hool to consume global ads solr index data and transform it into a UI component format.
 */
const useStaffData = () => {
  const {
    staffIndexFilterAndTransform,
    destinationFilterAndTransform,
    staffIndexTransformFilter,
    staffIndexTransformLandingPage,
  } = useDataObjectTransform()

  /**
   * Get global ads for a given country nid.
   * @param {string} departmentNid The department id.
   * @param {string} [profileId] The profileId nid.
   * @return {Promise<SlideshowItemType[]>}
   */
  const getStaffData = async (departmentNid, profileId) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "staff_profile_index",
        ss_type: "staff_profile",
        ...(departmentNid ? { itm_staff_department_nid: departmentNid } : {}),
      })
      .sort({
        id: "asc",
      })
      .rows(100)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return departmentNid
      ? staffIndexFilterAndTransform(solrRecords, profileId)
      : staffIndexTransformLandingPage(solrRecords)
  }

  /**
   * Get staff departments for specific staff type.
   * @param {number} departmentNid The staff type id.
   * @return {Promise<DestinationsGridProps[]>}
   */
  const getDestinationGridData = async (staffTypeID) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "staff_profile_index",
        ss_type: "staff_department",
        its_staff_type_nid: staffTypeID,
      })
      .sort({
        ss_title_s: "asc",
      })
      .rows(20)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return destinationFilterAndTransform(solrRecords)
  }

  /**
   * Get staff profiles for specific destination.
   * @param {string} DestinationId The staff type id.
   * @return {Promise<DestinationsGridProps[]>}
   */
  const getStaffByDestination = async (DestinationId) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "staff_profile_index",
        ss_type: "staff_profile",
        itm_destinations_nid: DestinationId,
      })
      .sort({
        id: "asc",
        // TODO: Sort by document ss_title when multi-value sort issue is resolved.
        // https://therefore.atlassian.net/browse/GW-681
        // ss_title: "asc",
      })
      //.bq({ itm_destinations_nid: `${DestinationId}^5.0` })
      .defType("edismax")

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const data = staffIndexTransformFilter(solrRecords)

    return data
  }

  /**
   * Get all staff members.
   * @return {Promise<DestinationsGridProps[]>}
   */
  const getRandomStaff = async () => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "staff_profile_index",
        ss_type: "staff_profile",
      })
      .defType("edismax")

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const selectedSample = sampleSize(solrRecords, 5)
    const data = staffIndexTransformFilter(selectedSample)

    return data
  }

  return {
    getStaffData,
    getRandomStaff,
    getDestinationGridData,
    getStaffByDestination,
  }
}

export default useStaffData
