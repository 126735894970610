/**
 * Forms action types.
 * @enum {string}
 */
export const formsActionType = {
  SET_FORM_VALUES: "SET_FORM_VALUES",
  RESET_FORM_VALUES: "RESET_FORM_VALUES",
}

/**
 * @typedef {Object} Form
 * @property {string} formId Unique form ID
 * @property {Object<string, any>} values Form state values
 */

/**
 * @typedef {Object} FormActionPayload
 * @property {Form} form
 */

/**
 * @typedef {Object} FormAction
 * @property {formsActionType} type Action type key
 * @property {FormActionPayload} payload Action input data
 */

/**
 * Compose form action.
 *
 * @param {formsActionType} actionType Action type
 * @param {Form} form Form state
 * @return {FormAction}
 */
const composeDataAction = (actionType, form) => {
  return {
    type: actionType,
    payload: {
      form,
    },
  }
}

/**
 * Set form values.
 *
 * @param {Form} form Form data
 * @return {FormAction}
 */
export const setFormValues = (form) => {
  return composeDataAction(formsActionType.SET_FORM_VALUES, form)
}

/**
 * Reset form values.
 *
 * @param {string} formId
 * @return {FormAction}
 */
export const resetFormValues = (formId) => {
  return composeDataAction(formsActionType.RESET_FORM_VALUES, {
    formId,
    values: null,
  })
}
