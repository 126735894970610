import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"
import { get } from "lodash"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

type getArticlesSolrDataParams = {
  category?: string
  destination?: string
  country?: string
  pageIndex?: number
  pageSize?: number
}

const useArticleSearchData = () => {
  const { articleSearchFilterAndTransform, tripSearchFacetLabelValueTransform } =
    useDataObjectTransform()

  const getArticleSolrData = async ({
    destination,
    country,
    category,
    pageIndex = 0,
    pageSize = 9,
  }: getArticlesSolrDataParams) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "article_index",
        ss_type: "article",
        ...(category && {
          itm_article_category_nid: category,
        }),
        ...(destination && {
          itm_destinations_nid: destination,
        }),
        ...(country && { ss_gw_combined_country: country }),
      })
      .facet({
        on: true,
        field: [
          "ss_gw_combined_destination_str",
          "ss_gw_combined_country_str",
          "ss_gw_ways_to_travel_keyvalue_str",
          "ss_gw_travel_style_keyvalue_str",
        ],
        // limit: 100,
        // mincount: 1,
      })
      .sort({
        its_nid: "desc",
      })
      .rows(pageSize)
      .start(pageIndex)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const solrDestinations =
      response?.facet_counts?.facet_fields?.ss_gw_combined_destination_str || null
    const solrCountries = response?.facet_counts?.facet_fields?.ss_gw_combined_country_str || null
    const solrWaysToTravel =
      response?.facet_counts?.facet_fields?.ss_gw_ways_to_travel_keyvalue_str || null
    const solrTravelStyles =
      response?.facet_counts?.facet_fields?.ss_gw_travel_style_keyvalue_str || null
    const count: number = response?.response?.numFound ?? 0
    const totalPages: number = Math.ceil(count / pageSize)

    return {
      count,
      totalPages,
      results: articleSearchFilterAndTransform(solrRecords),
      destinations: tripSearchFacetLabelValueTransform(solrDestinations),
      countries: tripSearchFacetLabelValueTransform(solrCountries),
      experiences: [
        ...tripSearchFacetLabelValueTransform(solrWaysToTravel, "Trip Types"),
        ...tripSearchFacetLabelValueTransform(solrTravelStyles, "Trip Styles"),
      ],
    }
  }

  return { getArticleSolrData }
}

export default useArticleSearchData
