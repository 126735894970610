import { initialState } from "../initialState"
import { createReducer } from "@reduxjs/toolkit"
import { settingsActionType } from "../actions/settings"

/**
 * @returns {StateSettingsType} New Settings state.
 */
const settings = createReducer(initialState.settings, {
  [settingsActionType.SET_CURRENT_LANGUAGE]: (state, action) => {
    state.language = action.payload.language
  },

  [settingsActionType.CLEAR_NOTICE]: (state, action) => {
    state.clearedNotices.push(action.payload)
  },

  [settingsActionType.SET_COUNTRY]: (state, action) => {
    state.country = action.payload.country
  },

  [settingsActionType.SET_COUNTRYSEL_VISIBLE]: (state, action) => {
    state.countrySelVisible = action.payload.visible || false
  },
})

export default settings
