import { useCallback } from "react"
import { useSelector } from "@ggs/store"
import { numberFormatter } from "@ggs/utils"
import { partialRight } from "lodash"

export interface DrupalUserLocation {
  source: string
  ipAddress: string
  ipVersion: number
  timestamp: number
  originalData: {
    ipNumber: string
    ipVersion: number
    ipAddress: string
    mcc: string | null
    mnc: string | null
    mobileCarrierName: string | null
    weatherStationName: string | null
    weatherStationCode: string | null
    iddCode: string | null
    areaCode: string | null
    latitude: number
    longitude: number
    countryName: string | null
    countryCode: string | null
    usageType: string | null
    elevation: string | null
    netSpeed: string | null
    timeZone: string | null
    zipCode: string | null
    domainName: string | null
    isp: string | null
    cityName: string | null
    regionName: string | null
  }
  country: string
  countryCode: string
  region: string
  regionCode: string
  city: string
  zip: string
  latitude: number
  longitude: number
  timeZone: string
  isEuCountry: boolean
  isGdprCountry: boolean
}

type UserLocationHook = {
  getUserLocation: () => Promise<DrupalUserLocation | null>
  mapCountryToCurrency: (countryCode: string, isEurope: boolean) => string
  currencyFormatter: (value: number) => string
  mapSaveAmountToCurrency: (countryCode: string, isEurope: boolean) => string
}

const useUserLocation = (): UserLocationHook => {
  const userCountry = useSelector((state) => state.settings.country)

  /**
   * Get user location based through Drupal Smart IP location using the client request IP address.
   */
  const getUserLocation = async (): Promise<DrupalUserLocation | null> => {
    const baseUrl = process.env.GATSBY_DRUPAL_BASE_URL
    try {
      const response = await fetch(`${baseUrl}/api/gw-common-smartip-lookup`)
      const userCountryLocation: DrupalUserLocation = await response.json()
      return userCountryLocation
    } catch (error) {
      console.error("Error fetching user location", error)
    }

    return null
  }

  /**
   * Mapping function that determine the proper currency code based on the country code.
   * @returns {string} - The currency code based on the country code.
   */
  const mapCountryToCurrency = (countryCode: string, isEurope: boolean = false): string => {
    if (!countryCode) {
      return ""
    }

    const code = countryCode.toUpperCase()
    if (isEurope) {
      return "EUR"
    }

    switch (countryCode) {
      case "AU":
        return "AUD"
      case "CA":
        return "CAD"
      case "NZ":
        return "NZD"
      case "GB":
        return "GBP"
      case "US":
        return "USD"
      case "EU":
        return "EUR"
      default:
        return "USD"
    }
  }

  const mapSaveAmountToCurrency = (
    countryCode: string,
    isEurope: boolean = false,
    isOverride = false
  ): string => {
    if (!countryCode) {
      return ""
    }

    if (isEurope) {
      return "EUR"
    }

    switch (countryCode) {
      case "AU":
        return isOverride ? "save_amount_aud" : "its_save_amount_aud"
      case "CA":
        return isOverride ? "save_amount" : "its_save_amount"
      case "NZ":
        return isOverride ? "save_amount_nzd" : "its_save_amount_nzd"
      case "GB":
        return isOverride ? "save_amount_gbd" : "its_save_amount_gbd"
      case "US":
        return isOverride ? "save_amount_usd" : "its_save_amount_usd"
      case "EU":
        return isOverride ? "save_amount_eud" : "its_save_amount_eud"
      default:
        return isOverride ? "save_amount" : "its_save_amount"
    }
  }

  const currency = mapCountryToCurrency(userCountry?.code)
  const currencyFormatter = useCallback(
    partialRight(numberFormatter.currency, numberFormatter.CURRENCIES[currency]),
    [currency]
  )

  return { getUserLocation, mapCountryToCurrency, currencyFormatter, mapSaveAmountToCurrency }
}

export default useUserLocation
