import { get } from "lodash"

type LinkProps = {
  title: string
  uri: string
}

type useLinkProps = {
  link?: LinkProps
  href?: string
}

const useLink = ({ link, href }: useLinkProps) => {
  const label = link?.title
  const uri = get(link, "uri", href || "").replace("internal:", "")

  const isInternal = uri.indexOf("/") === 0
  const isAssetLink = /\/assets\/|\/static\//.test(uri)
  const isExternal = (uri.indexOf("http") === 0 && !isAssetLink) || uri.indexOf("tel:") === 0
  const isJumpLink = uri.startsWith("#")

  return {
    isInternal,
    isAssetLink,
    isExternal,
    isJumpLink,
    uri: uri.replace(/\/\//g, "/").replace("http:/", "http://").replace("https:/", "https://"),
    label,
  }
}

export default useLink
