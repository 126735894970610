import { useMemo } from "react"
import { isArray, keyBy } from "lodash"

/**
 * @typedef {import("../../definitions/DrupalEntities").GenericParagraph} GenericParagraph
 */

/**
 * @typedef {Object} ComponentIndexProps
 * @property {GenericParagraph[]} components

/**
 * Hook to compose components paragraphs index by type.
 * @param {ComponentIndexProps} props
 */
const useComponentsIndex = (components) => {
  /**
   * Process index creation by type attribute.
   * @param {GenericParagraph[]} components
   * @return {Record<string, GenericParagraph[]>}
   */
  const getComponentsIndex = (components) => {
    return keyBy(components, "type")
  }

  const componentsIndex = useMemo(() => {
    if (!isArray(components)) {
      return {}
    }

    return getComponentsIndex(components)
  }, [components])

  return componentsIndex
}

export default useComponentsIndex
