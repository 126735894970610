import { useSelector } from "@ggs/store"
import type { TripTeaserProps } from "../../components/TripTeaser/TripTeaser"
import solrConfig from "../config/solr.json"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import useDataObjectTransform from "./useDataObjectTransform"
import useUserLocation from "./useUserLocation"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

type getTripsSolrDataParams = {
  destination?: string
  country?: string
  tripStyles?: string
  tripTypes?: string
  budget?: {
    min: number | null
    max: number | null
  }
  location?: string
  duration?: {
    min: number | null
    max: number | null
  }
  currency?: string
  pageIndex?: number
  pageSize?: number
  sortField?: string | null
  sortDirection?: string
  specials?: boolean
  limit?: number
}

const priceFields = [
  "its_package_price_aus",
  "its_package_price_cad",
  "its_package_price_eur",
  "its_package_price_gbp",
  "its_package_price_nzd",
  "its_package_price_usd",
]

const useTripsSearchData: any = () => {
  const {
    tripSearchFilterAndTransform,
    tripSearchFacetLabelValueTransform,
    tripSearchFacetLocationTransform,
    tripSearchFilterAndTransformByCountry,
  } = useDataObjectTransform()
  const { mapCountryToCurrency } = useUserLocation()
  // Determine the price field based on the user's country.
  const userCountryInfo = useSelector((state) => state.settings.country)

  /**
   * Resolve currency code for current user based on country location.
   */
  const resolveUserCurrency = () => {
    const countryCode = userCountryInfo?.code || "US"
    const countryIsEurope = userCountryInfo?.isEurope || false
    const currencyCode = mapCountryToCurrency(countryCode, countryIsEurope).toLowerCase()

    return currencyCode
  }

  /**
   * Lookup the Solr price field to use for current user.
   */
  const getUserPriceField = () => {
    // Use the provided currency code or resolve the user's currency.
    const currencyCode = resolveUserCurrency()
    const currentPriceField = `its_package_price_${currencyCode}`

    return currentPriceField
  }

  /**
   * Lookup the Solr price sort field to use for current user.
   */
  const getUserPriceSortField = () => {
    const currencyCode = resolveUserCurrency()
    const currentPriceField = `its_package_price_${currencyCode}`

    return currentPriceField
  }

  interface FacetItem {
    label: string
    value: string
  }

  interface TripsSolrData {
    count: number
    totalPages: number
    results: TripTeaserProps[] | null
    destinations: FacetItem[]
    countries: FacetItem[]
    locations?: FacetItem[]
    experiences: FacetItem[]
    prices: FacetItem[]
    pricesMinMax?: { min: number; max: number }
    durations: FacetItem[]
    durationsMinMax?: { min: number; max: number }
  }

  const getTripsSolrData = async ({
    destination,
    country,
    tripStyles,
    tripTypes,
    budget = {
      min: null,
      max: null,
    },
    duration = {
      min: null,
      max: null,
    },
    pageIndex = 0,
    pageSize = 2,
    sortField = null,
    sortDirection = "asc",
    specials = false,
    limit = 300,
  }: getTripsSolrDataParams = {}): Promise<TripsSolrData> => {
    const currentPriceField = getUserPriceField()
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      // Query that have group field unset (null) or false (not true).
      .customq(["-bs_groups:true OR (*:* -bs_groups:[* TO *])"])
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        ...(specials && { bs_special_offer: specials }),
        ...(destination && {
          ss_gw_combined_destination: destination,
        }),
        ...(country && { ss_gw_combined_country: country }),
        //@ts-ignore
        ...(tripTypes && { its_ways_to_travel_nid: `(${tripTypes?.replaceAll(",", " OR ")})` }),
        //@ts-ignore
        ...(tripStyles && { its_travel_style_nid: `(${tripStyles?.replaceAll(",", " OR ")})` }),
      })
      .facet({
        on: true,
        field: [
          "ss_gw_combined_destination_str",
          "ss_gw_combined_country_str",
          "ss_gw_combined_destination_country_str",
          "ss_gw_ways_to_travel_keyvalue_str",
          "ss_gw_travel_style_keyvalue_str",
          "its_duration",
          ...priceFields,
        ],
        limit,
        mincount: 1,
      })
      .sort({
        bs_special_offer: "desc",
        its_popularity: "desc",
        ...(sortField && { [sortField]: sortDirection }),
      })
      .rows(pageSize)
      .start(pageIndex)

    // Add budget price filter when budget options are provided. (0 is a valid value)
    if (budget && (budget.min || budget.min === 0) && (budget.max || budget.max === 0)) {
      solrQuery.rangeFilter({
        field: currentPriceField,
        start: budget.min,
        end: budget.max,
      })
    }

    // Add duration filter when duration options are provided. (0 is a valid value)
    if (duration && (duration.min || duration.min === 0) && (duration.max || duration.max === 0)) {
      solrQuery.rangeFilter({
        field: "its_duration",
        start: duration.min,
        end: duration.max,
      })
    }

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    const solrDestinations =
      response?.facet_counts?.facet_fields?.ss_gw_combined_destination_str || null
    const solrCountries = response?.facet_counts?.facet_fields?.ss_gw_combined_country_str || null
    const solrDestinationsAndCountries =
      response?.facet_counts?.facet_fields?.ss_gw_combined_destination_country_str || null
    const solrWaysToTravel =
      response?.facet_counts?.facet_fields?.ss_gw_ways_to_travel_keyvalue_str || null
    const solrTravelStyles =
      response?.facet_counts?.facet_fields?.ss_gw_travel_style_keyvalue_str || null
    const solrPrices = response?.facet_counts?.facet_fields?.[currentPriceField] || null
    const solrDurations = response?.facet_counts?.facet_fields?.its_duration || null
    const count: number = response?.response?.numFound ?? 0
    const totalPages: number = Math.ceil(count / pageSize)

    const prices = tripSearchFacetLabelValueTransform(solrPrices)
    const durations = tripSearchFacetLabelValueTransform(solrDurations)

    return {
      count,
      totalPages,
      results: tripSearchFilterAndTransform(solrRecords) || null,
      destinations: tripSearchFacetLabelValueTransform(solrDestinations),
      countries: tripSearchFacetLabelValueTransform(solrCountries),
      locations: tripSearchFacetLocationTransform(solrDestinationsAndCountries),
      experiences: [
        ...tripSearchFacetLabelValueTransform(solrWaysToTravel, "Trip Types"),
        ...tripSearchFacetLabelValueTransform(solrTravelStyles, "Trip Styles"),
      ],
      prices,
      pricesMinMax: {
        min: Math.min(...prices.map((price) => Number(price.value))) ?? 0,
        max: Math.max(...prices.map((price) => Number(price.value))) ?? 999999,
      },
      durations,
      durationsMinMax: {
        min: Math.min(...durations.map((duration) => Number(duration.value))) ?? 1,
        max: Math.max(...durations.map((duration) => Number(duration.value))) ?? 999,
      },
    }
  }

  const prepareTripsSearchResponseData = (response: any, pageSize: number) => {
    const currentPriceField = getUserPriceField()
    const solrRecords = response?.response?.docs || null
    const solrLocations = response?.facet_counts?.facet_fields?.ss_gw_geo_pages_keyvalue_str || null
    const solrWaysToTravel =
      response?.facet_counts?.facet_fields?.ss_gw_ways_to_travel_keyvalue_str || null
    const solrTravelStyles =
      response?.facet_counts?.facet_fields?.ss_gw_travel_style_keyvalue_str || null
    const solrPrices = response?.facet_counts?.facet_fields?.[currentPriceField] || null
    const solrDurations = response?.facet_counts?.facet_fields?.its_duration || null
    const solrCountries = response?.facet_counts?.facet_fields?.ss_gw_combined_country_str || null
    const count: number = response?.response?.numFound ?? 0
    const totalPages: number = Math.ceil(count / pageSize)

    return {
      count,
      totalPages,
      results: tripSearchFilterAndTransformByCountry(solrRecords),
      locations: tripSearchFacetLabelValueTransform(solrLocations),
      experiences: [
        ...tripSearchFacetLabelValueTransform(solrWaysToTravel, "Trip Types"),
        ...tripSearchFacetLabelValueTransform(solrTravelStyles, "Trip Styles"),
      ],
      prices: tripSearchFacetLabelValueTransform(solrPrices),
      durations: tripSearchFacetLabelValueTransform(solrDurations),
      countries: tripSearchFacetLabelValueTransform(solrCountries),
    }
  }

  const getTripsSolrDataByFilters = async ({
    country,
    location,
    tripStyles,
    tripTypes,
    budget = {
      min: null,
      max: null,
    },
    duration = {
      min: null,
      max: null,
    },
    pageIndex = 0,
    pageSize = 2,
    sortField = null,
    sortDirection = "asc",
    specials = false,
  }: getTripsSolrDataParams = {}) => {
    const currentPriceField = getUserPriceField()
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .customq(["-bs_groups:true OR (*:* -bs_groups:[* TO *])"])
      .q({
        index_id: "trip_index",
        ss_type: "trip",
        ...(specials && { bs_special_offer: specials }),
        ...(country && { ss_gw_combined_country: country }),
        ...(location && { ss_gw_geo_pages_keyvalue: location }),
        //@ts-ignore
        ...(tripTypes && { itm_ways_to_travel_nid: `(${tripTypes?.replaceAll(",", " OR ")})` }),
        //@ts-ignore
        ...(tripStyles && { itm_travel_style_nid: `(${tripStyles?.replaceAll(",", " OR ")})` }),
      })
      .facet({
        on: true,
        field: [
          "ss_gw_geo_pages_keyvalue_str",
          "ss_gw_ways_to_travel_keyvalue_str",
          "ss_gw_travel_style_keyvalue_str",
          "its_duration",
          "ss_gw_combined_country_str",
          ...priceFields,
        ],
        limit: 200,
        mincount: 1,
      })
      .sort({
        bs_special_offer: "desc",
        its_popularity: "desc",
        ...(sortField && { [sortField]: sortDirection }),
      })
      .rows(pageSize)
      .start(pageIndex)

    // Add budget price filter when budget options are provided.
    if (budget?.min && budget?.max) {
      solrQuery.rangeFilter({
        field: currentPriceField,
        start: budget.min,
        end: budget.max,
      })
    }

    // Add duration filter when duration options are provided.
    if (duration?.min && duration?.max) {
      solrQuery.rangeFilter({
        field: "its_duration",
        start: duration.min,
        end: duration.max,
      })
    }

    const response = await solrClient.runQuery(solrQuery)
    return prepareTripsSearchResponseData(response, pageSize)
  }

  return {
    getTripsSolrData,
    getTripsSolrDataByFilters,
    getUserPriceField,
    getUserPriceSortField,
  }
}
export default useTripsSearchData
