const bodyTrim = (data, wordsCount) => {
  if (!data) {
    return ""
  }
  const body = data.replace(/(<([^>]+)>)/gi, "")
  const bodyArray = body.split(" ")
  const bodyTrimmed = bodyArray.slice(0, wordsCount).join(" ")
  // add ... if the body is longer than the wordsCount
  if (bodyArray.length > wordsCount) {
    return `${bodyTrimmed}...`
  }
  return bodyTrimmed
}

export default bodyTrim
