import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

type unit =
  | "day"
  | "week"
  | "quarter"
  | "month"
  | "year"
  | "hour"
  | "minute"
  | "second"
  | "millisecond"

export const timeFromNow = (date: dayjs.ConfigType, unit: unit, round = false) => {
  return dayjs(date).diff(dayjs(), unit, round)
}

export const formatRelativeDate = (date: dayjs.ConfigType, format: string) => {
  const d = dayjs(date)
  if (dayjs().diff(d, "day") > 7) return dayjs(date).format(format)
  return d.fromNow()
}

export const formatPublishedDate = (date: dayjs.ConfigType, format: string) => {
  const d = dayjs(date)
  if (dayjs().diff(d, "day") > 7) return `Published on ${dayjs(date).format(format)}`
  return `Published ${d.fromNow()}`
}
