import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

/**
 * Hook to consume geo entity data and transform it into a UI component format.
 */
const useGeoPagesSolrData = () => {
  const { subPagesIndexToDynamicSubPageItems } = useDataObjectTransform()

  /**
   * Get sub pages for a given parentId.
   * @param {string} parentId The parent nid.
   * @returns {Promise<{links: {label: string, url: string}[]}>}
   */
  const getDynamicSubNav = async (parentId) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    const queryQ = {
      index_id: "geo_entity_index",
      ss_type: "sub_page",
    }
    if (parentId) {
      queryQ.its_parent_nid = parentId
    }

    solrQuery.q(queryQ).sort({ timestamp: "asc" })

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return subPagesIndexToDynamicSubPageItems(solrRecords)
  }

  return {
    getDynamicSubNav,
  }
}

export default useGeoPagesSolrData
