import { uniqueId } from "lodash"

/**
 * @typedef {import('../initialState').AlertMessage} NotificationMessage
 * @typedef {import('../initialState').AlertType} NotificationType
 * @typedef {import('../initialState').Stepper} Stepper
 * @typedef {import('../initialState').StepperStepsMeta} StepperStepsMeta
 * @typedef {import('../initialState').StepperStepStatus} StepperStepStatus
 */

/**
 * UI action types.
 * @enum {string}
 */
export const uiActionType = {
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",
  GOTO_STEPPER_STEP: "GOTO_STEPPER_STEP",
  HIDE_SIDEBAR: "HIDE_SIDEBAR",
  NEXT_STEPPER_STEP: "NEXT_STEPPER_STEP",
  PREVIOUS_STEPPER_STEP: "PREVIOUS_STEPPER_STEP",
  SET_STEPPER_STEP_STATUS: "SET_STEPPER_STEP_STATUS",
  SHOW_SIDEBAR: "SHOW_SIDEBAR",
  START_STEPPER: "START_STEPPER",
  RESET_SELECTED_SEARCH_RESULT: "RESET_SEARCH_RESULT",
  SELECT_SEARCH_RESULT: "SELECT_SEARCH_RESULT",
}

/**
 * @typedef {Object} StepperActionPayload
 * @property {string} id Stepper ID
 * @property {number=} stepsTotal Total number of steps
 * @property {number=} stepIndex Step to move to
 * @property {Object<number, StepperStepsMeta>=} meta Steps meta information
 */

/**
 * @typedef {Object} StepperAction
 * @property {uiActionType} type Action type key
 * @property {StepperActionPayload=} payload Action payload data
 */

/**
 * @typedef {Object} SidebarTypeActionPayload
 * @property {Boolean} visible
 * @property {string} contentType
 */

/**
 * @typedef {Object} SidebarAction
 * @property {uiActionType} type Action type key
 * @property {SidebarTypeActionPayload} payload Action payload data
 */

/**
 * Compose customer action.
 *
 * @param {uiActionType} actionType UI action type
 * @param {Boolean} visible Visibility flag state
 * @param {string} contentType Content type to display
 * @return {SidebarAction}
 */
const composeSidebarAction = (actionType, visible, contentType) => {
  return {
    type: actionType,
    payload: {
      visible,
      contentType,
    },
  }
}

/**
 * Action to show the webapp sidebar.
 *
 * @param {string} contentType Content type to display
 * @return {SidebarAction} Action object.
 */
export const showSidebar = (contentType) => {
  return composeSidebarAction(uiActionType.SHOW_SIDEBAR, true, contentType)
}

/**
 * Action to hide the webapp sidebar.
 * @return {SidebarAction} Action object.
 */
export const hideSidebar = () => {
  return composeSidebarAction(uiActionType.SHOW_SIDEBAR, false, "")
}

/**
 * @typedef {Object} AddNotificationAction
 * @property {uiActionType} type Action type key
 * @property {NotificationMessage} payload Action payload data
 */

/**
 * Add UI notification message.
 *
 * @param {string} code Message code
 * @param {NotificationType} type Message type
 * @param {string} title Message title
 * @param {string} body Message body
 * @return {AddNotificationAction} Action object.
 */
export const addNotification = (code, type = "info", title = "", body = "") => {
  return {
    type: uiActionType.ADD_NOTIFICATION,
    payload: {
      id: uniqueId("notification"),
      visible: true,
      code,
      title,
      body,
      type,
    },
  }
}

/**
 * @typedef {Object} ClearNotificationsAction
 * @property {uiActionType} type Action type key
 * @property {null} payload
 */

/**
 * Action to clear UI notifications.
 * @return {ClearNotificationsAction} Action object.
 */
export const clearNotifications = () => {
  return {
    type: uiActionType.CLEAR_NOTIFICATIONS,
    payload: null,
  }
}

/**
 * @typedef {Object} ClearNotificationActionPayload
 * @property {String} id
 */

/**
 * @typedef {Object} ClearNotificationAction
 * @property {uiActionType} type Action type key
 * @property {ClearNotificationActionPayload} payload
 */

/**
 * Action to clear specific UI notification by ID.
 * @param {String} id The notification ID to clear
 * @return {ClearNotificationAction} Action object.
 */
export const clearNotification = (id) => {
  return {
    type: uiActionType.CLEAR_NOTIFICATION,
    payload: {
      id,
    },
  }
}

/**
 * Action to go to next stepper step.
 * @param {string} stepperId Stepper ID
 * @return {StepperAction} Action object.
 */
export const nextStepperStep = (stepperId) => {
  return {
    type: uiActionType.NEXT_STEPPER_STEP,
    payload: {
      id: stepperId,
    },
  }
}

/**
 * Action to go to previous stepper step.
 * @param {string} stepperId Stepper ID
 * @return {StepperAction} Action object.
 */
export const previousStepperStep = (stepperId) => {
  return {
    type: uiActionType.PREVIOUS_STEPPER_STEP,
    payload: {
      id: stepperId,
    },
  }
}

/**
 * Action to go to previous stepper step.
 * @param {string} stepperId Stepper ID
 * @param {number} stepIndex Step index to move to
 * @return {StepperAction} Action object.
 */
export const gotoStepperStep = (stepperId, stepIndex) => {
  return {
    type: uiActionType.GOTO_STEPPER_STEP,
    payload: {
      id: stepperId,
      stepIndex: stepIndex,
    },
  }
}

/**
 * Action to start stepper state.
 * @param {string} stepperId Stepper ID
 * @param {number} stepsTotal Number of the steps that stepper instance will contain
 * @param {Object<number, StepperStepsMeta>} stepsMeta Steps meta information
 * @param {number=} defaultStepIndex Default step to pre-select
 * @return {StepperAction} Action object.
 */
export const startStepper = (stepperId, stepsTotal, stepsMeta, defaultStepIndex = 0) => {
  return {
    type: uiActionType.START_STEPPER,
    payload: {
      id: stepperId,
      stepsTotal: stepsTotal,
      stepIndex: defaultStepIndex,
      meta: stepsMeta,
    },
  }
}

/**
 * @typedef {Object} SetStepperStatusActionPayload
 * @property {string} id Stepper ID
 * @property {number=} stepsTotal Total number of steps
 * @property {number=} stepIndex Step to move to
 * @property {StepperStepsMeta} meta Step meta information
 */

/**
 * @typedef {Object} SetStepperStatusAction
 * @property {uiActionType} type Action type key
 * @property {SetStepperStatusActionPayload} payload Action payload data
 */

/**
 * Action to set stepper step status.
 * @param {string} stepperId Stepper ID
 * @param {number} stepIndex Step index to set status for
 * @param {StepperStepStatus} status Status to set
 * @return {SetStepperStatusAction} Action object.
 */
export const setStepperStepStatus = (stepperId, stepIndex, status) => {
  return {
    type: uiActionType.SET_STEPPER_STEP_STATUS,
    payload: {
      id: stepperId,
      stepIndex: stepIndex,
      meta: {
        status,
        dependingSteps: [],
      },
    },
  }
}

/**
 * @typedef {Object} SelectSearchResultActionPayload
 * @property {string} resultId Search result ID
 */

/**
 * @typedef {Object} SelectSearchResultAction
 * @property {uiActionType} type Action type key
 * @property {SelectSearchResultActionPayload} payload Action payload data
 */

/**
 * Action to select a search result item.
 * @param {string} resultId Search result ID
 * @return {SelectSearchResultAction} Action object.
 */
export const selectSearchResult = (resultId) => {
  return {
    type: uiActionType.SELECT_SEARCH_RESULT,
    payload: {
      resultId,
    },
  }
}

/**
 * @typedef {Object} ResetSelectedSearchResultAction
 * @property {uiActionType} type Action type key
 * @property {null} payload
 */

/**
 * Action to reset selected search result.
 * @return {ResetSelectedSearchResultAction} Action object.
 */
export const resetSelectedSearchResult = () => {
  return {
    type: uiActionType.RESET_SELECTED_SEARCH_RESULT,
    payload: null,
  }
}
