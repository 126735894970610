import { useCallback, useLayoutEffect, useState, type RefObject } from "react"

const useTruncatedElement = ({
  ref,
  titleRef = null,
}: {
  ref: RefObject<HTMLElement>
  titleRef: RefObject<HTMLElement> | null
}) => {
  const [isClamped, setIsClamped] = useState(false)
  const [isShowingMore, setIsShowingMore] = useState(false)

  const checkClamped = useCallback(() => {
    const { offsetHeight, scrollHeight } = ref?.current || {}

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsClamped(true)
    } else {
      setIsClamped(false)
    }
  }, [ref])

  useLayoutEffect(() => {
    checkClamped()
    window.addEventListener("resize", checkClamped)
    return () => window.removeEventListener("resize", checkClamped)
  }, [ref])

  const toggleIsShowingMore = () => {
    const shouldScrollToTop = isShowingMore // Determine if we need to scroll to top
    setIsShowingMore((prev) => !prev)

    // Smooth scroll to top when collapsing
    if (shouldScrollToTop && titleRef) {
      titleRef.current?.scrollIntoView({ behavior: "smooth" })
    } else {
      ref.current?.scrollIntoView({ behavior: "smooth" })
    }
  }

  return {
    isClamped,
    isShowingMore,
    toggleIsShowingMore,
  }
}

export default useTruncatedElement
