import { initialState } from "../initialState"
import { createReducer } from "@reduxjs/toolkit"
import { formsActionType } from "../actions/forms"

/**
 * @typedef {import("../initialState").StateForms} StateForms
 * @typedef {import("../actions/forms").FormAction} FormAction
 */

/**
 * @returns {StateForms} New UI state.
 */
const forms = createReducer(initialState.forms, {
  /**
   * Set the form values in forms state.
   * @param {StateForms} state
   * @param {FormAction} action
   */
  [formsActionType.SET_FORM_VALUES]: (state, action) => {
    const { formId = "unknown", values = {} } = action.payload.form
    state[formId] = values
  },

  /**
   * Reset the form values in form state.
   * @param {StateForms} state
   * @param {FormAction} action
   */
  [formsActionType.RESET_FORM_VALUES]: (state, action) => {
    const { formId = "unknown" } = action.payload.form
    state[formId] = null
  },
})

export default forms
