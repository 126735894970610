/**
 * Session action types.
 * @enum {string}
 */
export const sessionActionType = {
  SET_DRUPAL_TOKEN: "SET_DRUPAL_TOKEN",
}


/**
 * @typedef {Object} SetTokenActionPayload
 * @property {string} token
 */

/**
 * @typedef {Object} SetDrupalTokenAction
 * @property {sessionActionType} type Action type key
 * @property {SetTokenActionPayload} payload Action payload data
 */

/**
 * Action to set drupal invite session token.
 *
 * @param {string} token Drupal token
 * @return {SetDrupalTokenAction} Action object.
 */
export const setDrupalToken = (token) => {
  return {
    type: sessionActionType.SET_DRUPAL_TOKEN,
    payload: {
      token,
    },
  }
}
