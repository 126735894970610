import { combineReducers } from "redux"
import forms from "./forms"
import session from "./session"
import settings from "./settings"
import ui from "./ui"
import views from "./views"

export default combineReducers({
  forms,
  session,
  settings,
  ui,
  views,
})
