import { get, isArray, isEmpty, isNumber, isString, isObject } from "lodash"
const isStorybook = `${process.env.STORYBOOK}` === "yes"

/**
 * @typedef {Object} DoApiRequestProps
 * @property {string} endpoint
 * @property {any} data
 * @property {Object<string, string>} customHeaders
 * @property {string} method
 */

/**
 * @typedef {function} ApiRequestBaseConstructorProps
 * @param {string} baseUrl
 * @param {string=} authToken
 * @param {string=} csrfToken
 */

/**
 * @typedef {(props: DoApiRequestProps) => Promise<any>} DoApiRequest
 * @typedef {(props: Record<string, any>) => string} PrepareQueryParams
 * @typedef {(props: ApiRequestBaseConstructorProps) => Promise<any>} ApiRequestBaseConstructor
 */

/**
 * Base API request service, provide methods to perform API requests.
 * @property {DoApiRequest} doApiRequest
 * @property {PrepareQueryParams} prepareQueryParams
 * @property {string} baseUrl
 * @property {string=} authToken
 * @property {string=} csrfToken
 * @property {Object<string, ApiRequestBase>} instance
 */
class ApiRequestBase {
  static instance = {}

  /**
   * Api request base instance constructor.
   * @param baseUrl API base URL.
   * @param authToken= API authentication token.
   * @param csrfToken= API CSRF token.
   */
  constructor({ baseUrl, authToken, csrfToken }) {
    this.baseUrl = baseUrl
    this.authToken = authToken
    this.csrfToken = csrfToken

    // Ensure only one instance of this class is created per base URL.
    if (isObject(get(ApiRequestBase.instance, baseUrl, null))) {
      return ApiRequestBase.instance[baseUrl]
    } else {
      ApiRequestBase.instance = {}
    }

    // Instantiate instance for this base URL API.
    ApiRequestBase.instance[baseUrl] = this
    return this
  }

  /**
   * @typedef {Object} CsrfHeader
   * @property {string=} X-CSRF-Token
   */

  /**
   * Compose a CSRF header for API requests.
   * @return {CsrfHeader}
   */
  prepareCsrfHeader = () => {
    if (isStorybook) {
      return {}
    }

    return {
      "X-CSRF-Token": this.csrfToken,
    }
  }

  /**
   * Process a Drupal API endpoint request.
   *
   * @type {DoApiRequest}
   * @param {DoApiRequestProps} props
   * @return {Promise<any>}
   */
  doApiRequest = async ({ endpoint, data = null, customHeaders, method = "" }) => {
    const url = `${this.baseUrl}${endpoint}`
    console.log("doApiRequestUrl:", url)
    const jsonData = data ? JSON.stringify(data) : ""
    const csrfHeader = this.prepareCsrfHeader()
    const authHeader = {
      Authorization: `Bearer ${this.authToken}`,
    }
    const errorCodes = [
      400, 401, 403, 405, 406, 408, 409, 410, 412, 422, 429, 500, 501, 502, 503, 504,
    ]

    /**
     * @type {RequestInit}
     */
    const options = {
      method: method || "POST",
      headers: {
        //'Content-Type': 'application/json',
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
        ...(this.authToken && isStorybook ? authHeader : {}),
        ...(isEmpty(csrfHeader) ? {} : csrfHeader),
        ...(customHeaders || {}),
      },
    }

    if (data) {
      options.body = jsonData
    } else {
      options.method = method || "GET"
    }

    console.log(`doRequestURL: ${url} - ${JSON.stringify(options)}`)
    try {
      const response = await fetch(url, options)
      // If is a success response return data, otherwise null to indicate failure.
      if ([200, 201].includes(response.status)) {
        const contentType = response.headers.get("content-type")
        if (contentType.includes("json")) {
          return await response.json()
        } else {
          return {
            data: response,
          }
        }
      } else if (errorCodes.includes(response.status)) {
        const errorCode = response.status
        return {
          data: response,
          errorCode,
        }
      }
    } catch (error) {
      console.log("ERROR API request:", error)
      return null
    }
  }

  /**
   * Prepare query string params.
   * @type {PrepareQueryParams}
   * @param {Record<string, any>} params
   * @return {string}
   */
  prepareQueryParams = (params) => {
    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((currentValue) => {
          searchParams.append(key, `${currentValue}`)
        })
      } else if (isString(value) || isNumber(value)) {
        searchParams.append(key, `${value}`)
      } else {
        console.error(`Parameter ${key} value type not supported:`, value)
      }
    })

    return searchParams.toString()
  }
}

export default ApiRequestBase
