import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"
import useDataObjectTransform from "./useDataObjectTransform"

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

const useTravelGuideData = () => {
  const { travelGuideFilterAndTransform, nextTravelGuideTransform, wishlistGuideGuideTransform } =
    useDataObjectTransform()
  /**
   * Get travel guides data for a given country
   * @param {string} countryId The viewed country id.
   * @param {string} excludedTravelGuideId Id of element to be excluded from the results.
   * @param {string} startIndex Start index of the Solr request to handle pagination.
   * @return {Promise<any[]>}
   */
  const getTravelGuides = async (countryId, excludedTravelGuideId, startIndex = 0) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "travel_guide_index",
        ss_type: "travel_guide",
        itm_country_nid: countryId,
      })
      .sort({
        id: "asc",
      })
      .start(startIndex)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return {
      pages: Math.ceil(response?.response?.numFound / 10),
      cards: travelGuideFilterAndTransform(solrRecords, excludedTravelGuideId),
    }
  }

  const getNextTravelGuideByNodeId = async (id) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "travel_guide_index",
        ss_type: "travel_guide",
        ...(id ? { its_nid: `[${id + 1} TO *]` } : {}),
      })
      .sort({ id: "asc" })
      .rows(1)

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return nextTravelGuideTransform(solrRecords)
  }

  const getTravelGuideByNid = async (ids, amount) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "travel_guide_index",
        ss_type: "travel_guide",
        its_nid: `(${ids})`,
      })
      .sort({ id: "asc" })
      .rows(amount)
    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null
    return wishlistGuideGuideTransform(solrRecords)
  }

  return { getTravelGuides, getNextTravelGuideByNodeId, getTravelGuideByNid }
}

export default useTravelGuideData
