import { useState, useEffect } from "react"
import useGeoPagesSolrData from "./useGeoPagesSolrData"

/**
 * @typedef {Object} DynamicSubNavParent
 * @property {string} title The title of the parent page.
 * @property {string} uri The uri of the parent page.
 * @property {string} nid The nid of the parent page.
 */

/**
 * Compose a dynamic sub navigation based on the current viewed geo page.
 * @param {DynamicSubNavParent} parent The parent page.
 */
const useDynamicSubNav = (props) => {
  const { title, uri, nid } = props
  const { getDynamicSubNav } = useGeoPagesSolrData()
  const [subNav, setSubNav] = useState(null)

  useEffect(() => {
    if (nid) {
      getDynamicSubNav(nid).then((data) => {
        const overviewLink = { title: `${title} Overview`, uri }
        const composedLinks = data ? [overviewLink].concat(data) : [overviewLink]
        setSubNav(composedLinks)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nid])

  return subNav
}

export default useDynamicSubNav
