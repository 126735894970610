// @ts-nocheck
import getWindow from "./getWindow"

const win = getWindow()

/**
 * @param {number} adjusted Window top position.
 */
export const top = (adjusted = 0) => {
  win.scrollTo({
    top: adjusted,
    behavior: "smooth",
  })
}

export const elementById = (elementId) => {
  const section = document.querySelector(`#${elementId}`)
  if (!section) {
    return
  }

  section.scrollIntoView({
    behavior: "smooth",
    block: "start",
  })
}
