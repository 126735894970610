// @ts-nocheck
import { debounce, size, forEach, delay } from "lodash"

const QUEUE_TYPE = {
  micro: "micro",
  short: "short",
  long: "long",
}

const queue = {
  [QUEUE_TYPE.micro]: {},
  [QUEUE_TYPE.short]: {},
  [QUEUE_TYPE.long]: {},
}

/**
 * Trigger the processing of current queue jobs.
 * @param {string} type Queue type (micro, short, long)
 */
const fireQueue = (type = QUEUE_TYPE.short) => {
  // If we have callbacks, trigger them
  const currentQueue = queue[type]
  if (size(currentQueue)) {
    // Start deferring callbacks at 0s, incrementing gas we go to space out state handling.
    let deferAt = 0
    const deferIncrement = 5

    //console.log('Queue::firing queue', currentQueue)
    forEach(currentQueue, (cb, name) => {
      // Trigger
      delay(() => cb(), deferAt)
      // Clear out of queue
      delete currentQueue[name]
      // Increase defer
      deferAt += deferIncrement
    })
    //console.log('Queue::queue reset', currentQueue)
  } else {
    //console.log('Queue::fired empty', currentQueue)
  }
}

/**
 * Debounce short queue delay.
 * @type {DebouncedFunc<function(): void>}
 */
const shortQueue = debounce(() => fireQueue(), 1000)

/**
 * Debounce long queue delay.
 * @type {DebouncedFunc<function(): void>}
 */
const longQueue = debounce(() => fireQueue(QUEUE_TYPE.long), 4000)

/**
 * Debounce micro queue with 1ms delay.
 * @type {DebouncedFunc<function(): void>}
 */
const microQueue = debounce(() => fireQueue(QUEUE_TYPE.micro), 1)

/**
 * Debounce queue factory that allow to produce current query of a given type.
 * @param {string} key Queue ID key
 * @param {function} callback Job processing callback
 * @param {string} type Queue type (micro, short, long)
 */
export default function debounceQueue(key, callback, type = QUEUE_TYPE.short) {
  // Add cb to list of cb's running
  // Set latest cb
  queue[type][key] = callback
  // console.log('Queue::adding cb, attempt debounce', key, type)

  switch (type) {
    case QUEUE_TYPE.short:
      shortQueue()
      break
    case QUEUE_TYPE.long:
      longQueue()
      break
    case QUEUE_TYPE.micro:
      microQueue()
      break
    default:
      shortQueue()
      break
  }
}

debounceQueue.QUEUE_TYPE = QUEUE_TYPE
