/**
 * Implement Gatsby's Browser APIs.
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
const { wrapRootElement } = require("./libs/gatsby")

exports.wrapRootElement = wrapRootElement
// exports.onPreRouteUpdate = onPreRouteUpdate
// exports.onRouteUpdate = onRouteUpdate
// exports.wrapPageElement = wrapPageElement
// exports.onInitialClientRender = onInitialClientRender
// exports.onClientEntry = onClientEntry
