// @ts-nocheck
const { get } = require("lodash")

/**
 * A safe convenient way to obtain the window, or part of it, in i18n DOM without build errors.
 * @param {string} path Window property path as required by lodash get function, e.x 'location.protocol'
 * @return {Object|Array|String|Number|function}
 */
const getWindow = (path = "") => {
  const win = typeof window !== "undefined" ? window : {}
  return path === "" ? win : get(win, path, null)
}

module.exports = getWindow
