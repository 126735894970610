import { initialState } from "../initialState"
import { createReducer, current } from "@reduxjs/toolkit"
import { uiActionType } from "../actions/ui"
import { extend } from "lodash"

/**
 * @typedef {import("../initialState").StateUI} StateUI
 * @typedef {import("../actions/ui").SidebarAction} UISidebarAction
 * @typedef {import("../actions/ui").AddNotificationAction} AddNotificationAction
 * @typedef {import("../actions/ui").StepperAction} StepperAction
 */

/**
 * @returns {StateUI} New UI state.
 */
const ui = createReducer(initialState.ui, {
  /**
   * Show sidebar reducer.
   * @param {StateUI} state
   * @param {UISidebarAction} action
   */
  [uiActionType.SHOW_SIDEBAR]: (state, action) => {
    state.sidebar = action.payload
  },

  /**
   * Hide sidebar reducer.
   * @param {StateUI} state
   * @param {UISidebarAction} action
   */
  [uiActionType.HIDE_SIDEBAR]: (state, action) => {
    state.sidebar = action.payload
  },

  /**
   * Add notification message.
   * @param {StateUI} state
   * @param {AddNotificationAction} action
   */
  [uiActionType.ADD_NOTIFICATION]: (state, action) => {
    state.alerts.notifications = state.alerts.notifications.concat(action.payload)
  },

  /**
   * Clear UI notifications.
   * @param {StateUI} state
   * @param {any} action
   */
  [uiActionType.CLEAR_NOTIFICATIONS]: (state, action) => {
    state.alerts.notifications = action.payload || []
  },

  /**
   * Clear UI notifications by ID.
   * @param {StateUI} state
   * @param {any} action
   */
  [uiActionType.CLEAR_NOTIFICATION]: (state, action) => {
    const alertId = action.payload.id
    state.alerts.notifications = state.alerts.notifications.filter(
      (notification) => notification.id !== alertId
    )
  },

  /**
   * Start stepper instance state.
   * @param {StateUI} state
   * @param {StepperAction} action
   */
  [uiActionType.START_STEPPER]: (state, action) => {
    const stepperId = action.payload.id
    state.steppers[stepperId] = {
      currentStep: action.payload.stepIndex || 0,
      stepsTotal: action.payload.stepsTotal,
      meta: {
        ...action.payload.meta,
      },
    }
  },

  [uiActionType.NEXT_STEPPER_STEP]: (state, action) => {
    const stepperId = action.payload.id
    const steppersState = current(state.steppers)
    const stepperState = steppersState[stepperId]
    state.steppers[stepperId].currentStep = stepperState.currentStep + 1
  },

  [uiActionType.PREVIOUS_STEPPER_STEP]: (state, action) => {
    const stepperId = action.payload.id
    const steppersState = current(state.steppers)
    const stepperState = steppersState[stepperId]
    state.steppers[stepperId].currentStep = stepperState.currentStep - 1
  },

  [uiActionType.GOTO_STEPPER_STEP]: (state, action) => {
    const stepperId = action.payload.id
    const steppersState = current(state.steppers)
    const stepperState = steppersState[stepperId]
    const stepIndex = action.payload.stepIndex
    const newStepIndex = stepIndex <= stepperState.stepsTotal ? stepIndex : 0
    state.steppers[stepperId].currentStep = newStepIndex
  },

  [uiActionType.SET_STEPPER_STEP_STATUS]: (state, action) => {
    const stepperId = action.payload.id
    const stepIndex = action.payload.stepIndex
    const steppersState = current(state.steppers)
    const stepperState = steppersState[stepperId]
    const newStepMeta = action.payload.meta
    const stepperStateMeta = extend({}, stepperState.meta, {
      [stepIndex]: {
        ...stepperState.meta[stepIndex],
        status: newStepMeta.status,
      },
    })

    // console.log('finalStepperStateMeta:', stepperStateMeta)
    state.steppers[stepperId] = {
      ...stepperState,
      meta: stepperStateMeta,
    }
  },

  [uiActionType.RESET_SELECTED_SEARCH_RESULT]: (state, _action) => {
    state.map.selectedResultId = null
  },

  [uiActionType.SELECT_SEARCH_RESULT]: (state, action) => {
    const { resultId } = action.payload
    state.map.selectedResultId = resultId
  },

})

export default ui
