import { useState, useEffect, useCallback } from "react"
import { get, toString } from "lodash"
import { getWindow } from "@ggs/utils"

type Value = string | string[] | number | boolean | null
type Params = Record<string, Value>

const useUrlParams = (params: Params, basePath?: string) => {
  const [paramsState, setParams] = useState<Params>(() => {
    const locationSearch = toString(getWindow("location.search")) || ""
    const searchParams = new URLSearchParams(locationSearch)
    const initialValues: Params = {}
    Object.keys(params).forEach((key) => {
      const paramValues = searchParams.getAll(key)
      if (paramValues.length > 0) {
        initialValues[key] = paramValues.length > 1 ? paramValues : paramValues[0]
      } else {
        initialValues[key] = params[key]
      }
    })
    return initialValues
  })

  useEffect(() => {
    const newSearchParams = new URLSearchParams()
    Object.keys(paramsState).forEach((key) => {
      const value = paramsState[key]
      if (value !== null) {
        if (Array.isArray(value)) {
          value.forEach((v) => newSearchParams.append(key, v))
        } else {
          if (value === "") {
            newSearchParams.delete(key)
          } else {
            newSearchParams.set(key, String(value))
          }
        }
      }
    })
    const currentPath = basePath || get(window, "location.pathname")
    const queryString = newSearchParams.toString()
    const newUrl = `${currentPath}?${newSearchParams.toString()}`
    if (queryString) {
      window.history.pushState(null, "", newUrl)
    }
  }, [paramsState])

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      location.reload()
    }
    window.addEventListener("popstate", handlePopState)
    return () => window.removeEventListener("popstate", handlePopState)
  }, [])

  const setValue = useCallback((key: string, value: Value) => {
    setParams((prevParams) => ({ ...prevParams, [key]: value }))
  }, [])

  const resetParams = useCallback((params: Params = {}) => {
    setParams({ ...params })
  }, [])

  return { params: paramsState, setParam: setValue, resetParams }
}

export default useUrlParams
