import { hashValue } from "@ggs/utils"

/**
 * @typedef {import("../initialState").CountryInfo} CountryInfo
 */

/**
 * Settings action types.
 * @enum {string}
 */
export const settingsActionType = {
  CLEAR_NOTICE: "CLEAR_NOTICE",
  SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE",
  SET_COUNTRY: "SET_COUNTRY",
  SET_COUNTRYSEL_VISIBLE: "SET_COUNTRYSEL_VISIBILE",
}

/**
 * @typedef {Object} SetCurrentLanguageActionPayload
 * @property {String} language
 */

/**
 * @typedef {Object} SetCurrentLanguageAction
 * @property {settingsActionType} type Action type key
 * @property {SetCurrentLanguageActionPayload} payload Action input data
 */

/**
 * Set current market action.
 *
 * @param {String} language CL market ID
 * @return {SetCurrentLanguageAction}
 */
export const setCurrentLanguage = (language) => {
  return {
    type: settingsActionType.SET_CURRENT_LANGUAGE,
    payload: {
      language,
    },
  }
}

/**
 * @typedef {Object} SetCountryActionPayload
 * @property {CountryInfo} country
 */

/**
 * @typedef {Object} SetCountryAction
 * @property {settingsActionType} type Action type key
 * @property {SetCountryActionPayload} payload Action input data
 */

/**
 * Set current user located country information.
 *
 * @param {CountryInfo} country User country information
 * @return {SetCurrentLanguageAction}
 */
export const setCountry = (country) => {
  return {
    type: settingsActionType.SET_COUNTRY,
    payload: {
      country,
    },
  }
}

/**
 * @typedef {Object} ClearNoticeAction
 * @property {settingsActionType} type Action type key
 * @property {String} payload
 */

/**
 * Action to clear specific UI alert by ID.
 * @param {String} label The alert ID to clear
 * @return {ClearNoticeAction} Action object.
 */
export const clearNotice = (label) => {
  return {
    type: settingsActionType.CLEAR_NOTICE,
    payload: hashValue(label),
  }
}

/**
 * @typedef {Object} SetCountrySelVisibleActionPayload
 * @property {Boolean} visible
 */

/**
 * @typedef {Object} SetCountrySelAction
 * @property {settingsActionType} type Action type key
 * @property {SetCountrySelVisibleActionPayload} payload Action payload
 */

/**
 * Set country selector visibility setting.
 *
 * @param {boolean} visible
 * @return {SetCountrySelAction}
 */
export const setCountrySelVisible = (visible) => {
  return {
     type: settingsActionType.SET_COUNTRYSEL_VISIBLE,
     payload: {
       visible,
     }
  }
}