const getPricePrefix = (currency: string): string => {
  switch (currency) {
    case "EUR":
      return "€"
    case "GBP":
      return "£"
    case "AUD":
    case "AUS":
      return "AU$"
    case "USD":
      return "US$"
    case "NZD":
      return "NZ$"
    case "CAD":
    default:
      return "C$"
  }
}

export { getPricePrefix }
